import React from 'react';
import './Submissions.css';

const Submissions = () => (
  <section id="submissions" className="submissions">
    <div className="container">
      <img src="/optimized-images/submissions.webp" alt="Submission" className="submission-image" />
    </div>
  </section>
);

export default Submissions;