import React from 'react';
import './Sponsorships.css';

const Sponsorships = () => (
  <section id="sponsorships" className="sponsorships">
    <div className="container">
      <img src="/optimized-images/sponsorships.webp" alt="Sponsorships" className="sponsorship-image" />
    </div>
  </section>
);

export default Sponsorships;